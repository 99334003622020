import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';
import PaginationGolbal from '../../../components/pagination/PaginationGlobal';
import PageTitle from '../../../components/pageTitle/PageTitle';
import cms from '../../../app/api/cms';
import { Spinner } from 'reactstrap';
import { Alert } from 'reactstrap';
import i18n from '../../../app/i18n';
import './WebSubmissionsPage.scss';
import SubmissionDeleteModal from '../../../components/modals/submissionDeleteModal/SubmissionDeleteModal';

const WebSubmissionsPage = () => {
  const [loadingRows, setLoadingRows] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [rows, setRows] = useState([]);
  const [totalItemsCount, setTotalItemsCount] = useState(0);

  useEffect(() => {
    loadRows(currentPage, '');
  }, [currentPage]);

  const handlePageChange = (newActivePage) => {
    setCurrentPage(newActivePage);
  };

  const loadRows = async (currentPageIndex) => {
    setRows([]);

    await cms
      .GetWebSubmissions(currentPageIndex)
      .then(function (response) {
        if (response.data.data !== undefined) {
          setRows(response.data.data.rows);
          setTotalItemsCount(response.data.data.total_count);
          setLoadingRows(false);
        }
      })
      .catch(function (error) {});
  };

  function getWebsubmissionStatuses(item) {
    let statusClass = '';

    if (item.status === 'reviewed') {
      statusClass = 'secondary';
    } else if (item.status === 'in_review') {
      statusClass = 'info';
    } else if (item.status === 'new') {
      statusClass = 'success';
    } else if (item.status === 'need_callback') {
      statusClass = 'danger';
    } else {
      statusClass = 'secondary';
    }
    return <Badge color={statusClass}>{i18n.t(`webSubmisionsStatuses.${item.status}`)}</Badge>;
  }

  return (
    <div className='web-submissions-page'>
      <PageTitle>Bendros užklausos</PageTitle>
      <div className='table-box'>
        <div className='upper-side with-filter'>
        </div>
        {loadingRows === true ? (
          <Spinner>Loading...</Spinner>
        ) : (
          <>
            {rows && rows.length > 0 ? (
              <>
                <div className='table-all'>
                  <div className='table-row main'>
                    <div style={{ width: '10%' }}>
                      <h6>ID</h6>
                    </div>
                    <div style={{ width: '10%' }}>
                      <h6>Sukurta</h6>
                    </div>
                    <div style={{ width: '20%' }}>
                      <h6>Statusas</h6>
                    </div>
                    <div style={{ width: '30%' }}>
                      <h6>Klientas</h6>
                    </div>
                    <div style={{ width: '20%' }}>
                      <h6>Perskambinimo data</h6>
                    </div>
                    <div style={{ width: '10%' }}>
                      <h6>Veiksmai</h6>
                    </div>
                  </div>
                  {rows.map((item, i) => (
                    <div className='table-row row-new' key={`${item.id}-webSubmissions`}>
                      <div style={{ width: '10%' }} className='mobile-style'>
                        <h6 className='mobile-titles'>ID</h6>
                        <p>{item.id !== null ? item.id : '-'} </p>
                      </div>
                      <div style={{ width: '10%' }} className='mobile-style'>
                        <h6 className='mobile-titles'>SUKURTA</h6>
                        <p>{item.created_at !== null ? item.created_at : '-'}</p>
                      </div>
                      <div style={{ width: '20%' }} className='mobile-style'>
                        <h6 className='mobile-titles'>STATUSAS</h6>
                        <p>{getWebsubmissionStatuses(item)}</p>
                      </div>
                      <div style={{ width: '30%' }} className='mobile-style'>
                        <h6 className='mobile-titles'>KLIENTAS</h6>
                        <p>
                          {item.full_name !== null ? item.full_name : '-'}
                          <br />
                          {item.email !== null ? item.email : '-'}
                          <br />
                          {item.phone !== null ? item.phone : '-'}
                        </p>
                      </div>
                      <div style={{ width: '20%' }} className='mobile-style'>
                        <h6 className='mobile-titles'>PERSKAMBINIMO DATA</h6>
                        <p>{item.callback_date !== null ? item.callback_date : '-'}</p>
                      </div>
                      <div style={{ width: '10%' }} className='d-flex flex-column align-items-center mobile-style'>
                        <Link to={`/cms/admin/web-submissions/${item.id}/review`} className='seen-btn'>
                          <span>Peržiūrėti</span>
                        </Link>
                        <SubmissionDeleteModal submissionId={item.id} />
                      </div>
                    </div>
                  ))}
                </div>
                <div className='pagination-container'>
                  <PaginationGolbal activePage={currentPage} totalItemsCount={totalItemsCount} handlePageChange={handlePageChange} />
                </div>
              </>
            ) : (
              <Alert style={{ marginLeft: '20px', marginRight: '20px' }} color='primary'>
                Bendrų užklausų nėra
              </Alert>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default WebSubmissionsPage;
