import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import cms from '../../../app/api/cms';
import PageTitle from '../../../components/pageTitle/PageTitle';
import ClientsTable from './ClientsTable';
import InviocesTable from './InviocesTable';
import WebsubmissionTable from './WebsubmissionTable';
import './DashoarPage.scss';

const DashboardPage = () => {
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadDashboardData();
  }, []);

  const loadDashboardData = async () => {
    setLoading(true);

    await cms
      .GetDashboardData()
      .then(function (response) {
        if (response.data !== undefined) {
          setDashboardData(response.data);
          setLoading(false);
        }
      })
      .catch(function (error) {});
  };

  return (
    <>
      {loading === true ? (
        <Spinner>Loading...</Spinner>
      ) : (
        <>
          <div className='dashboad-page' style={{ marginBottom: '0px' }}>
            <PageTitle>Dashboard</PageTitle>
            <ClientsTable dashboardData={dashboardData.clients} />
            <WebsubmissionTable dashboardData={dashboardData.submissions} />
            <InviocesTable dashboardData={dashboardData.invoices} />
          </div>
        </>
      )}
    </>
  );
};

export default DashboardPage;
