import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PaginationGolbal from '../../../../components/pagination/PaginationGlobal';
import { Badge } from 'reactstrap';
import PageTitle from '../../../../components/pageTitle/PageTitle';
import { useSelector, useDispatch } from 'react-redux';
import ClientProfileAddSubmissionModal from '../../../../components/modals/clientProfileAddSubmission/ClientProfileAddSubmissionModal';
import { setStatuses } from '../../../../app/features/submissions';
import cms from '../../../../app/api/cms';
import i18n from '../../../../app/i18n';
import { Spinner, Alert } from 'reactstrap';
import StatusBadge from '../../invoices/invoicesList/StatusBadge';
import TypeBadge from '../../invoices/invoicesList/TypeBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faFileDownload } from '@fortawesome/free-solid-svg-icons';

const InvoicesTable = ({ client }) => {
  const dispatch = useDispatch();
  const statuses = useSelector((state) => state.invoices.statuses);
  const types = useSelector((state) => state.invoices.types);
  const [loadingStatuses, setLoadingStatuses] = useState(true);
  const [loadingRows, setLoadingRows] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [rows, setRows] = useState([]);
  const [totalItemsCount, setTotalItemsCount] = useState(0);

  useEffect(() => {
    loadInvoices();
  }, [currentPage]);

  const loadInvoices = async () => {
    setRows([]);

    await cms
      .GetClientInvoices(client.id, currentPage)
      .then(function (response) {
        if (response.data.data !== undefined) {
          setRows(response.data.data.rows);
          setTotalItemsCount(response.data.data.total_count);
          setLoadingRows(false);
        }
      })
      .catch(function (error) {});
  };

  function StatusHtml(elStatus) {
    if (statuses && statuses.length > 0) {
        let findStatus = statuses.find((el) => el.value === elStatus);
        if (findStatus !== -1) {
            return <StatusBadge status={elStatus} text={findStatus.label} />;
        }
    }
}

function TypeHtml(elType) {
    if (types && types.length > 0) {
      let findType = types.find((el) => el.value === elType);
      if (findType !== -1) {
        return <TypeBadge type={elType} text={findType.label} />;
      }
    }
  }

  const handlePageChange = (newActivePage) => {
    setCurrentPage(newActivePage);
  };
  return (
    <div className='submission-client-prof-page' style={{ marginTop: '8px' }}>
      <PageTitle>Kliento sąskaitos</PageTitle>
      <div className='table-box'>
        {loadingRows === true && loadingStatuses === true ? (
          <Spinner>Loading...</Spinner>
        ) : (
          <>
            <div className='table-all'>
              {rows && rows.length > 0 ? (
                <>
                  <div className='table-row main'>
                                <div style={{ width: '10%' }}>
                                    <h6>ID</h6>
                                </div>
                                <div style={{ width: '30%' }}>
                                    <h6>Sąskaita / Klientas</h6>
                                </div>
                                <div style={{ width: '15%' }}>
                                    <h6>Statusas</h6>
                                </div>
                                <div style={{ width: '15%' }}>
                                    <h6>Tipas</h6>
                                </div>
                                <div style={{ width: '20%' }}>
                                    <h6>Suma</h6>
                                </div>
                                <div style={{ width: '10%' }}>
                                    <h6>Veiksmai</h6>
                                </div>
                            </div>
                  {rows.map((row) => (
                    <div className='table-row' key={`${row.id}-rows`}>
                    <div style={{ width: '10%' }} className='mobile-style'>
                        <h6 className='mobile-titles'>ID</h6>
                        <p>{row.id}</p>
                    </div>
                    <div style={{ width: '30%' }} className='mobile-style'>
                        <h6 className='mobile-titles'>SĄSKAITA / KLIENTAS</h6>
                        <p>
                            {row.invoice_type == 'before_time' ? (<>NR. {row.before_time_identifier_prefix}-{row.before_time_identifier}</>) : (<>NR. {row.identifier_prefix}-{row.identifier}</>)}
                            
                            <br />
                            {row.buyer_name}
                        </p>
                    </div>
                    <div style={{ width: '15%' }} className='mobile-style'>
                        <h6 className='mobile-titles'>STATUSAS</h6>
                        <p>{StatusHtml(row.status)}</p>
                    </div>
                    <div style={{ width: '15%' }} className='mobile-style'>
                        <h6 className='mobile-titles'>TIPAS</h6>
                        <p>{TypeHtml(row.invoice_type)}</p>
                    </div>
                    <div style={{ width: '20%' }} className='mobile-style'>
                        <h6 className='mobile-titles'>SUMA</h6>
                        <p>{row.grand_total} &euro;</p>
                    </div>
                    <div style={{ width: '10%' }} className='mobile-style'>
                        <h6 className='mobile-titles'>VEIKSMAI</h6>
                        <p className='icons-table mobile-small-icon'>
                            <Link to={`/cms/admin/invoices/${row.id}/edit`}>
                                <FontAwesomeIcon icon={faPenToSquare} className='mob-r' />
                            </Link>
                            {row.downloadLink ? (
                                <a className='text-info' href={row.downloadLink} download='download'>
                                    <FontAwesomeIcon icon={faFileDownload} className='mob-r' />
                                </a>
                            ) : (
                                ''
                            )}
                        </p>
                    </div>
                </div>
                  ))}
                  <PaginationGolbal
                    activePage={currentPage}
                    totalItemsCount={totalItemsCount}
                    handlePageChange={handlePageChange}
                    itemsCountPerPage={10}
                  />
                </>
              ) : (
                <Alert style={{ marginLeft: '20px', marginRight: '20px' }} color='primary'>
                  Sąskaitų nera.
                </Alert>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default InvoicesTable;
