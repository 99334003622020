import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import PageTitle from '../../../components/pageTitle/PageTitle';
import AssignMeModal from '../../../components/modals/assignMe/AssignMeModal';
import CreateClientModal from '../../../components/modals/createClient/CreateClientModal';
import SubmisionStatusModal from '../../../components/modals/submissionStatus/SubmisionStatusModal';
import { Badge } from 'reactstrap';
import RecallModal from '../../../components/modals/recall/RecallModal';
import cms from '../../../app/api/cms';
import i18n from '../../../app/i18n';
import './ReviewSubmisstionPage.scss';

const ReviewSubmisstionPage = () => {
  const [submissionData, setSubmissionData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { rowID } = useParams();

  useEffect(() => {
    loadSubmission();
  }, []);

  const loadSubmission = async () => {
    setLoading(true);
    setSubmissionData(null);

    await cms
      .GetWebSubmissionData(rowID)
      .then(function (response) {
        if (response.data.data !== undefined) {
          setSubmissionData(response.data.data);
          setLoading(false);
        }
      })
      .catch(function (error) {});
  };

  function getWebsubmissionStatuses(item) {
    let statusClass = '';

    if (item.status === 'reviewed') {
      statusClass = 'secondary';
    } else if (item.status === 'in_review') {
      statusClass = 'info';
    } else if (item.status === 'new') {
      statusClass = 'success';
    } else if (item.status === 'need_callback') {
      statusClass = 'danger';
    } else {
      statusClass = 'secondary';
    }
    return <Badge color={statusClass}>{i18n.t(`webSubmisionsStatuses.${item.status}`)}</Badge>;
  }

  return (
    <div className='review-sub-page'>
      <PageTitle>Užklausos peržiūra</PageTitle>
      {loading === true ? (
        <Spinner>Loading...</Spinner>
      ) : (
        <>
          <div className='table-box'>
            <div className='upper-side with-filter'>
              <h6 className='d-flex flex-column'>
                Užklausos ID: {submissionData.id !== null ? submissionData.id : '-'}
                {submissionData.status !== 'reviewed' ? (
                  <span className='mt-3'>
                    <SubmisionStatusModal submissionDataID={submissionData.id} />
                  </span>
                ) : (
                  ''
                )}
              </h6>
            </div>
            <div className='table-all d-flex'>
              <div className='table-row d-flex flex-column border'>
                <div className='d-flex border-bottom align-items-center p-2 sub-rev-mobile'>
                  <div style={{ width: '30%' }}>
                    <h6>Sukurta</h6>
                  </div>
                  <div style={{ width: '70%' }}>
                    <p>{submissionData.created_at !== null ? submissionData.created_at : '-'}</p>
                  </div>
                </div>
                <div className='d-flex border-bottom align-items-center p-2 sub-rev-mobile'>
                  <div style={{ width: '30%' }}>
                    <h6>Vardas Pavardė</h6>
                  </div>
                  <div style={{ width: '70%' }}>
                    <p>{submissionData.full_name !== null ? submissionData.full_name : '-'}</p>
                  </div>
                </div>
                <div className='d-flex border-bottom align-items-center p-2 sub-rev-mobile'>
                  <div style={{ width: '30%' }}>
                    <h6>Telefonas</h6>
                  </div>
                  <div style={{ width: '70%' }}>
                    <p>{submissionData.phone !== null ? submissionData.phone : '-'}</p>
                  </div>
                </div>

                <div className='d-flex border-bottom align-items-center p-2 sub-rev-mobile'>
                  <div style={{ width: '30%' }}>
                    <h6>El. paštas</h6>
                  </div>
                  <div style={{ width: '70%' }}>
                    <p>{submissionData.email !== null ? submissionData.email : '-'}</p>
                  </div>
                </div>

                <div className='d-flex border-bottom align-items-center p-2 sub-rev-mobile'>
                  <div style={{ width: '30%' }}>
                    <h6>Statusas</h6>
                  </div>
                  <div style={{ width: '70%' }}>
                    <p>{getWebsubmissionStatuses(submissionData)}</p>
                  </div>
                </div>

                <div className='d-flex border-bottom align-items-center p-2 sub-rev-mobile'>
                  <div style={{ width: '30%' }}>
                    <h6>Perskambinimo data</h6>
                  </div>
                  <div style={{ width: '70%' }}>
                    <p>{submissionData.callback_date !== null ? submissionData.callback_date : '-'}</p>
                  </div>
                </div>

                <div className='d-flex border-bottom align-items-center p-2 sub-rev-mobile'>
                  <div style={{ width: '30%' }}>
                    <h6>Komentaras</h6>
                  </div>
                  <div style={{ width: '70%' }}>
                    <p> {submissionData.content !== null ? submissionData.content : '-'}</p>
                  </div>
                </div>
                {submissionData.broker !== undefined && submissionData.broker !== null ? (
                  <div className='d-flex align-items-center p-2 sub-rev-mobile'>
                    <div style={{ width: '30%' }}>
                      <h6>Brokeris</h6>
                    </div>
                    <div style={{ width: '70%' }}>
                      <p>
                        {submissionData.broker.name !== null ? submissionData.broker.name : '-'} {submissionData.broker.surname !== null ? submissionData.broker.surname : '-'}
                      </p>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className='buttons sub-rev-mobile'>
              <AssignMeModal assignId={submissionData.id} />
              <RecallModal recallId={submissionData.id} recallData={submissionData.callback_date} />
              {submissionData.client !== null ? (
                <Link className='client-create-btn' to={`/cms/admin/clients/${submissionData.client.id}/review`}>
                  Kliento peržiūra
                </Link>
              ) : (
                <CreateClientModal submissionData={submissionData} />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ReviewSubmisstionPage;
