import { useEffect, useState } from 'react';
import PageTitle from '../../../components/pageTitle/PageTitle';
import DeleteUserModal from '../../../components/modals/deleteUser/DeleteUserModal';
import AddClientLabelModal from '../../../components/modals/addClientLabel/AddClientLabelModal';
import cms from '../../../app/api/cms';
import i18n from '../../../app/i18n';
import EditClientLabelModal from '../../../components/modals/editClientLabel/EditClientLabelModal';
import { Alert } from 'reactstrap';

const ClientLabelsPage = () => {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    loadLabels();
  }, []);

  const loadLabels = async () => {
    setRows([]);
    await cms
      .GetClientLabels()
      .then(function (response) {
        if (response.data.data !== undefined) {
          setRows(response.data.data);
        }
      })
      .catch(function (error) {
      });
  };

  const handleDeleteRow = async (rowId) => {
    await cms
      .DeleteClientLabel(rowId)
      .then(function (response) {
        if (response.data.message === 'delete_successful') {
          loadLabels();
        }
      })
      .catch(function (error) {
      });
  };

  return (
    <div className='simple-table-page'>
      <PageTitle>{i18n.t('clientLabelsPage.title')}</PageTitle>
      <div className='table-box'>
        <div className='upper-side'>
          <AddClientLabelModal onSuccessHandler={() => loadLabels()} />
        </div>
        {rows && rows.length > 0 ? (
          <>
            <div className='table-all'>
              <div className='table-row main'>
                <div style={{ width: '10%' }}>
                  <h6>ID</h6>
                </div>
                <div style={{ width: '30%' }}>
                  <h6>Pavadinimas</h6>
                </div>
                <div style={{ width: '30%' }}>
                  <h6>Spalva</h6>
                </div>
                <div style={{ width: '20%' }}>
                  <h6>Spalvos kodas</h6>
                </div>
                <div style={{ width: '10%' }}>
                  <h6>Veiksmai</h6>
                </div>
              </div>
              {rows.map((row) => (
                <div className='table-row' key={`${row.id}-rows`}>
                  <div style={{ width: '10%' }} className='mobile-style'>
                    <h6 className='mobile-titles'>ID</h6>
                    <p>{row.id}</p>
                  </div>
                  <div style={{ width: '30%' }} className='mobile-style'>
                    <h6 className='mobile-titles'>PAVADINIMAS</h6>
                    <p>{row.title}</p>
                  </div>
                  <div style={{ width: '30%' }} className='mobile-style'>
                    <h6 className='mobile-titles'>SPALVA</h6>
                    <p>
                      <span
                        style={{
                          backgroundColor: row.color_hex,
                          width: '40px',
                          height: '40px',
                          display: 'flex',
                        }}
                      ></span>
                    </p>
                  </div>
                  <div style={{ width: '20%' }} className='mobile-style'>
                    <h6 className='mobile-titles'>SPALVOS KODAS</h6>
                    <p>{row.color_hex}</p>
                  </div>
                  <div style={{ width: '10%' }} className='mobile-style'>
                    <h6 className='mobile-titles'>VEIKSMAI</h6>
                    <p className='icons-table mobile-small-icon'>
                      <EditClientLabelModal
                        labelId={row.id}
                        labelObj={row}
                        onSuccessHandler={() => loadLabels()}
                      />
                      <DeleteUserModal
                        rowId={row.id}
                        onSuccess={handleDeleteRow}
                        textContent={`Ar tikrai norite ištrinti?`}
                        title={`Žymos ${row.title} trinimas`}
                      />
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          <Alert style={{ marginLeft: '20px', marginRight: '20px' }} color='primary'>
            žymų nera
          </Alert>
        )}
      </div>
    </div>
  );
};

export default ClientLabelsPage;
