import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import blankUserImg from '../../../images/blank-profile-picture-973460_1280.png';
import './ClientProfileHeader.scss';
import AssignClientToMeModal from '../../modals/assignClientToMe/AssignClientToMeModal';
import ClientProfileEditClientModal from '../../modals/clienProfileEditClient/ClientProfileEditClientModal';
import cms from '../../../app/api/cms';

const ClientProfileHeader = ({ client }) => {
    let currentUser = useSelector((state) => state.users.currentUser);

    const assignHandler = async () => {
        await cms
            .AssignClientToAdmin(client.id)
            .then(function (response) {
                if (response.data.message !== undefined && response.data.message === 'action_success') {
                    window.location.reload();
                }
            })
            .catch(function (error) {});
    };

    return (
        <div className='user-header header-comp'>
            <div className='bg-user'>
                <div className='box-user-img'>
                    <img className='profile-img' src={blankUserImg} alt='' />
                </div>
                <div className='header-client-info'>
                    <h4>
                        {client && client.name} {client && client.surname}
                    </h4>
                    <h5>
                        {client && client.email} | {client && client.phone}
                    </h5>
                </div>
            </div>
            <div className='d-flex menu'>
                <ul>
                    <li>
                        <NavLink className={(navData) => (navData.isActive ? 'active' : '')} to={`/cms/admin/clients/${client.id}/review`}>
                            Peržiūra
                        </NavLink>
                    </li>
                    <li>
                        <NavLink className={(navData) => (navData.isActive ? 'active' : '')} to={`/cms/admin/clients/${client.id}/submissions`}>
                            Užklausos
                        </NavLink>
                    </li>
                    <li>
                        <NavLink className={(navData) => (navData.isActive ? 'active' : '')} to={`/cms/admin/clients/${client.id}/invoices`}>
                            Sąskaitos
                        </NavLink>
                    </li>
                    {client.broker_id !== null && client.broker_id === currentUser.id ? (
                        <li>
                            <ClientProfileEditClientModal client={client} />
                        </li>
                    ) : (
                        <li>
                            <AssignClientToMeModal assignHandler={assignHandler} />
                        </li>
                    )}
                </ul>
            </div>
        </div>
    );
};

export default ClientProfileHeader;
