import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import PageTitle from '../../../components/pageTitle/PageTitle';
import DeleteUserModal from '../../../components/modals/deleteUser/DeleteUserModal';
import PaginationGolbal from '../../../components/pagination/PaginationGlobal';
import cms from '../../../app/api/cms';
import { Alert } from 'reactstrap';
import './UsersPage.scss';

const UsersPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rows, setRows] = useState([]);
  const [totalItemsCount, setTotalItemsCount] = useState(0);

  useEffect(() => {
    loadRows(currentPage);
  }, [currentPage]);

  const handlePageChange = (newActivePage) => {
    setCurrentPage(newActivePage);
  };

  const handleDeleteRow = async (userId) => {
    await cms
      .DeleteUser(userId)
      .then(function (response) {
        if (response.data.message === 'user_deleted') {
          loadRows(currentPage);
        }
      })
      .catch(function (error) {
      });
  };

  const loadRows = async (currentPageIndex) => {
    setRows([]);

    await cms
      .GetUsers(currentPageIndex)
      .then(function (response) {
        if (response.data.data !== undefined) {
          setRows(response.data.data.rows);
          setTotalItemsCount(response.data.data.total_count);
        }
      })
      .catch(function (error) {});
  };

  return (
    <div className='users-page'>
      <PageTitle>Vartotojai</PageTitle>
      <div className='table-box'>
        <div className='upper-side'>
          <Link to='/cms/admin/users/add'>Pridėti vartotoją</Link>
        </div>

        {rows && rows.length > 0 ? (
          <>
            <div className='table-all'>
              <div className='table-row main'>
                <div style={{ width: '10%' }}>
                  <h6>ID</h6>
                </div>
                <div style={{ width: '30%' }}>
                  <h6>Vardas ir Pavardė</h6>
                </div>
                <div style={{ width: '30%' }}>
                  <h6>El. paštas</h6>
                </div>
                <div style={{ width: '20%' }}>
                  <h6>Telefonas</h6>
                </div>
                <div style={{ width: '10%' }}>
                  <h6>Veiksmai</h6>
                </div>
              </div>
              {rows &&
                rows.map((row) => (
                  <div className='table-row' key={`${row.id}-rows`}>
                    <div style={{ width: '10%' }} className='mobile-style'>
                      <h6 className='mobile-titles'>ID</h6>
                      <p>{row.id}</p>
                    </div>
                    <div style={{ width: '30%' }} className='mobile-style'>
                      <h6 className='mobile-titles'>VARDAS IR PAVARDĖ</h6>
                      <p>
                        {row.name} {row.surname}
                      </p>
                    </div>
                    <div style={{ width: '30%' }} className='mobile-style'>
                      <h6 className='mobile-titles'>EL. PAŠTAS</h6>
                      <p>{row.email}</p>
                    </div>
                    <div style={{ width: '20%' }} className='mobile-style'>
                      <h6 className='mobile-titles'>TELEFONAS</h6>
                      <p>{row.phone === null ? '-' : row.phone}</p>
                    </div>
                    <div style={{ width: '10%' }} className='mobile-style pt-2'>
                      <h6 className='mobile-titles'>VEIKSMAI</h6>
                      <p className='icons-table mobile-small-icon'>
                        <Link to={`/cms/admin/users/${row.id}/edit`}>
                          <FontAwesomeIcon icon={faPenToSquare} className='mob-r' />
                        </Link>
                        <DeleteUserModal
                          rowId={row.id}
                          onSuccess={handleDeleteRow}
                          textContent={`Ar tikrai norite ištrinti?`}
                          title={`Vartotojo ${row.name} ${row.surname} trinimas`}
                        />
                      </p>
                    </div>
                  </div>
                ))}
            </div>
            <div className='pagination-container'>
              <PaginationGolbal
                activePage={currentPage}
                totalItemsCount={totalItemsCount}
                handlePageChange={handlePageChange}
                itemsCountPerPage={10}
              />
            </div>
          </>
        ) : (
          <Alert style={{ marginLeft: '20px', marginRight: '20px' }} color='primary'>
            Vartotojų nėra.
          </Alert>
        )}
      </div>
    </div>
  );
};

export default UsersPage;
